import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    alignItems: 'center',
    justify: 'center',
    container: true,
    margin: '50px'
  },
  button: {
    margin: '5px'
  }
}));

export default function Home() {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Box border={1}>
        <Typography variant="h2">Luna Design Styles</Typography>
      </Box>
      <Box m={5}>
        <Typography variant="subtitle2">
          Hello, thank you for visiting my page. Right now, we are working on building this website,
          but for now, you can check out my products on Facebook or Instagram and send me a message
          there to place an order.
        </Typography>
      </Box>
      <Box m={5}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => window.open('https://www.facebook.com/luna.designstyles', '_blank')}
        >
          <FacebookIcon color="primary" fontSize="large" />
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => window.open('https://www.instagram.com/luna.designstyles/', '_blank')}
        >
          <Instagram color="secondary" fontSize="large" />
        </Button>
      </Box>
    </Grid>
  );
}
